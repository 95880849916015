import { Link } from "gatsby"
import React from "react"

import { AllNavigation, SocialNavigation } from "../navigation"

const Footer = () => (
  <footer className="max-w-fullcontent-border bg-white">
    <div className="max-w-3xl  px-4 pt-2 mx-auto text-gray-500 text-xs pb-32 md:flex justify-between ">
      <div>
        <div className="mt-2">© {new Date().getFullYear()} Audyem GmbH</div>
      </div>
      <div className="md:flex justify-between md:text-right">
        <div className="mt-4 md:ml-8">
        <ul>
           {SocialNavigation.map((navigationItem, index) =>
            <li key={navigationItem.path} className={`hover:text-red-600 transition duration-200 ease-in-out`}>
              <a href={navigationItem.path} rel="noopener noreferrer" target="_blank">{navigationItem.name}</a>
            </li>
            )}
          </ul>
          
        </div>
        <div className="mt-4 md:ml-8">
          <ul>
           {AllNavigation.map((navigationItem, index) =>
            <li key={navigationItem.path} className={`hover:text-red-600 transition duration-200 ease-in-out`}>
              <Link to={navigationItem.path} activeClassName="text-black" partiallyActive={navigationItem.partiallyActive}>{navigationItem.name}</Link>
            </li>
            )}
          </ul>
        </div>
        <div className="mr-16 mt-4 md:ml-8 audyem-logo audyem-logo--footer flex justify-between md:text-right w-12 hidden md:inline"></div>
      </div>
    </div>
  </footer>
)

export default Footer