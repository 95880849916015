const _ITEMS = [
  {
    name: 'Solutions',
    id: 'solutions',
    isBase: true,
    partiallyActive: false,
    path: '/',
    subItems: [
      {
        name: 'Product Features',
        text: 'Learn more about Audyem and our engagement toolkit',
        path: '/solutions#product',
      },
      {
        name: 'Case Studies',
        text: 'See how other customers use Audyem to increase revenue',
        path: '/solutions#casestudies',
      },
      {
        name: 'Integration',
        text: 'Audyem plays in tune with your CRM or data pipelines.',
        path: '/solutions#integration',
      }
    ]
  },
  {
    name: 'Company',
    id: 'company',
    isBase: true,
    partiallyActive: true,
    path: '/company',
    subItems: [
      {
        name: 'Press',
        text: 'Read all the latest press releases',
        path: '/company#press',
      },
      {
        name: 'About',
        text: 'Find out more about the origins of Audyem here',
        path: '/company#about',
      },
      {
        name: 'Jobs',
        text: 'Check out our open positions',
        path: '/company#jobs',
      },
    ]
  },
  {
    name: 'Request Trial',
    isBase: true,
    topOnly: true,
    partiallyActive: true,
    path: '/request',
    subItems: []
  },
  {
    name: 'Imprint',
    isBase: false,
    partiallyActive: true,
    path: '/imprint',
    subItems: []
  },
  {
    name: 'Contact',
    isBase: false,
    partiallyActive: true,
    path: '/imprint#contact',
    subItems: []
  },
  {
    name: 'Privacy  ',
    isBase: false,
    partiallyActive: true,
    path: '/imprint#privacy',
    subItems: []
  }
]
const _SOCIAL = [
  {
    name: 'Twitter',
    path: 'https://nma.vc/'
  },
  {
    name: 'LinkedIn',
    path: 'https://linkedin.com/company/audyem'
  },
  {
    name: 'Pitchbook',
    path: 'https://pitchbook.com/profiles/company/433854-82#overview'
  }
]

export const BaseNavigation = _ITEMS.filter(e => e.isBase === true);
export const AllNavigation = _ITEMS.filter(e => e.topOnly !== true);
export const SocialNavigation = _SOCIAL;
