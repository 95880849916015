import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from 'react'
import { BaseNavigation } from '../navigation'

const Header = ({ siteTitle }) => {
  const toggleSubmenu = ({ navigationItem, shouldShow }) => {
    const submenu = document.querySelector(`#submenu-${navigationItem.id}`);
    submenu && submenu.classList.toggle('audyem-submenu--open', shouldShow);
  }

  const toggleMobileMenu = e => {
    const mobileMenu = document.querySelector(`.audyem-mobile-menu`);

    e && e.preventDefault();
    document.querySelector(`html`).classList.toggle(`overflow-hidden`);
    mobileMenu && mobileMenu.classList.toggle('audyem-mobile-menu--open');
  }

  return <div className="mx-6 md:mx-0">
    <header className="px-0 md:px-4 max-w-3xl pb-3 md:py-3 md:py-3 mx-auto flex justify-between items-center relative z-20">
      <Link to="/">
        <div className={`audyem-logo h-10 w-20 py-8`}></div>
      </Link>
      <button className="md:hidden audyem-mobile-menu-button cursor-pointer hover:opacity-100 z-20" onClick={toggleMobileMenu}></button>
      <div className="audyem-mobile-menu fixed w-screen h-screen inset-0 z-10">
        <div className="w-64 pl-6 flex flex-col text-left">
          <div className={`audyem-logo h-10 w-20 py-8`}></div>
          {BaseNavigation.map((navigationItem, index) =>
            <Link to={navigationItem.path} activeClassName="" className="text-gray-700 py-1 rounded-full" partiallyActive={navigationItem.partiallyActive}>
              <div className="block text-xs font-bold mb-1 text-gray-900">{navigationItem.name}</div>
              <ul>
                {navigationItem.subItems.map((subItem, index) =>
                  <li className="mb-3 text-gray-700 text-xs" key={subItem.path}>
                    <span className="font-bold block">
                      {subItem.name}
                    </span>
                    {subItem.text}
                  </li>
                )}
              </ul>
            </Link>
          )}
        </div>
      </div>

      <ul className="-mr-2 hidden md:inline">
      {BaseNavigation.map((navigationItem, index) =>
        <Link to={navigationItem.path} activeClassName="text-opacity-100 text-gray-800 audyem-shadow-inner font-bold" className="py-1 rounded-full" partiallyActive={navigationItem.partiallyActive}>
          <li onMouseEnter={() => toggleSubmenu({ navigationItem, shouldShow: true })} onMouseLeave={() => toggleSubmenu({ navigationItem, shouldShow: false })} key={navigationItem.path} className={`rounded-full py-4 px-3 inline-block text-sm transition duration-200 ease-in-out audyem-3d`}>
            {navigationItem.name}
          {!!navigationItem.subItems.length && 
            <ul id={`submenu-${navigationItem.id}`} className="w-64 text-gray-800 block font-normal absolute z-50 text-xs bg-white px-5 mt-2 pt-4 pb-2 shadow-xl rounded-lg audyem-submenu">
            {navigationItem.subItems.map((subItem, index) =>
              <li className="mb-3" key={subItem.path}>
                <span className="font-bold block" style={{'marginBottom': '0.1rem'}}>
                  {subItem.name}
                </span>
                {subItem.text}
              </li>
            )}
            </ul>
          }
        </li>
      </Link>
      )}
      </ul>
    </header>
  </div>
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
